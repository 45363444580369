import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import success from '../../../images/icon-success.png'
import error from '../../../images/icon-error.png'
import info from '../../../images/icon-info.png'
import warning from '../../../images/icon-warning.png'
import { MessageListBox, MessageItemBox } from './index.style'
import './index.style.css'

type Content = React.JSX.Element | string
type Method = (content: Content) => void

interface Message {
  success: Method
  error: Method
  warning: Method
  info: Method
}

interface MessageItemProps {
  id: number
  type: string
  content: Content
  onRemove: (id: number) => void
}

interface MessageListType {
  [id: string]: {
    id: number
    type: string
    content: Content
  }
}

const icons: { [key: string]: string } = {
  success,
  error,
  info,
  warning,
}
const message: Message = {
  success: () => {},
  error: () => {},
  warning: () => {},
  info: () => {},
}
const messageList: MessageListType = {}

const MessageItem = (props: MessageItemProps) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.onRemove?.(props.id)
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [props])

  return (
    <MessageItemBox>
      <img
        className="message-icon"
        src={icons[props.type]}
        alt=""
        loading="lazy"
      />
      {props.content}
    </MessageItemBox>
  )
}

const MessageList = () => {
  const [flagId, setFlagId] = useState<number>(0)

  const removeById = (id: number) => {
    delete messageList[id]
    setFlagId(flagId + 1)
  }

  const messageMethods = (type: string, content: Content) => {
    const id = 0
    messageList[id] = {
      id,
      type,
      content,
    }
    setFlagId(flagId + 1)
  }

  message.success = content => {
    messageMethods('success', content)
  }

  message.error = content => {
    messageMethods('error', content)
  }

  message.warning = content => {
    messageMethods('warning', content)
  }

  message.info = content => {
    messageMethods('info', content)
  }

  return (
    <MessageListBox>
      {Object.keys(messageList).map(key => {
        const item = messageList[key]
        return (
          <MessageItem
            key={item.id}
            id={item.id}
            type={item.type}
            content={item.content}
            onRemove={removeById}
          />
        )
      })}
    </MessageListBox>
  )
}
if (typeof document !== 'undefined') {
  const messageModelContainer = document.createElement('div')
  messageModelContainer.className = 'message-model-container'
  document.body.appendChild(messageModelContainer)
  ReactDOM.createRoot(messageModelContainer).render(<MessageList />)
}

export default message
