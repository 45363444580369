import React, { useEffect, useState } from 'react'

import { FormItemBox } from './index.style'

interface Props {
  className?: string
  label?: string
  rules?: {
    required?: boolean
    type?: string
    message: string
  }[]
  formValue?: string | number | undefined
  showMessage?: boolean
  getValidateRes?: { prop: string; validate: (params?: any) => void }
  children: React.ReactNode
}

const FormItem = (props: Props) => {
  const { label, rules = [], formValue, showMessage, getValidateRes } = props
  const [required, setRequired] = useState<boolean>(false)
  const [errMessage, setErrMessage] = useState<
    { type: string; text: string }[]
  >([])
  const [errStatus, setErrStatus] = useState<boolean>(false)

  const validateErrMap: Map<string, string> = new Map()

  useEffect(() => {
    const mapErr: { type: string; text: string }[] = []
    rules.forEach(rule => {
      // 必填校验
      if (rule.required) {
        setRequired(rule.required)
        if (!formValue) {
          validateErrMap.set('required', rule.message)
        } else {
          validateErrMap.delete('required')
        }
        setErrStatus(!formValue)
      }
      // 邮箱
      if (rule.type === 'email') {
        // 校验格式
        if (
          formValue &&
          !/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(
            String(formValue)
          )
        ) {
          validateErrMap.set('email', rule.message)
        } else {
          validateErrMap.delete('email')
        }

        // 检验长度
        if (
          formValue &&
          (String(formValue).length < 6 || String(formValue).length > 100)
        ) {
          validateErrMap.set(
            'emailLength',
            'Must have a minium of 6 characters and a maximum of 100 charaters.'
          )
        } else {
          validateErrMap.delete('emailLength')
        }
      }
    })
    validateErrMap.forEach((value, key) => {
      if (value) {
        mapErr.push({ type: key, text: value })
      }
    })
    setErrMessage([...mapErr])

    if (getValidateRes?.validate) {
      getValidateRes.validate({
        prop: getValidateRes.prop,
        value: mapErr.length,
      })
    }
  }, [formValue])

  return (
    <FormItemBox {...props}>
      {label && (
        <label htmlFor=" ">
          {label}
          {required && <span className="required">*</span>}
        </label>
      )}
      <div
        className={`form-item-content ${
          showMessage && (errStatus || errMessage.length) ? 'error-status' : ''
        }`}
      >
        {props.children}
      </div>
      {showMessage && errMessage.length
        ? errMessage.map((item, index) => (
            <div className="err-message" key={index}>
              {item.text}
            </div>
          ))
        : ''}
    </FormItemBox>
  )
}

export default FormItem
