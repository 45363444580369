import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'
import CenterBg from '../../../../images/contact/center-bg.png'
import CenterBgMobile from '../../../../images/contact/center-bg-mobile.png'
import Location from '../../../../images/contact/location.svg'
import LocationMobile from '../../../../images/contact/location-mobile.svg'

export const ContactWrapper = styled.div`
  position: relative;
  padding: 112px 0;
  background: #fff;
  &::after {
    content: '';
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 963px;
    height: 706px;
    background: url(${CenterBg}) no-repeat;
    z-index: 0;
  }
  h3 {
    color: #1f2329;
    text-align: center;
    font-size: 40px;
    font-weight: 800;
    text-align: center;
  }
  .map-wrapper {
    margin: 56px 0 112px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 298px;
    padding: 16px;
    border-radius: 24px;
    background: #fff;
    z-index: 1;
    box-shadow: 0px 20px 40px rgba(0, 90, 198, 0.08);
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 126px;
      height: 126px;
      background: url(${Location}) no-repeat;
    }
    .map-left {
      padding-left: 156px;
      h4 {
        color: #1f2329;
        font-size: 28px;
        font-weight: 500;
        line-height: 1.5;
      }
      p {
        margin-top: 12px;
        width: 339px;
        color: ${color.textDark};
        font-size: 18px;
        line-height: 1.5;
        span {
          font-weight: 500;
        }
      }
    }
    .map-right {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 584px;
      height: 266px;
      border-radius: 20px;
      background: #eaeff6;
    }
  }
  .contact-container {
    display: flex;
    justify-content: space-between;
    margin-top: 56px;
    width: 100%;
    .contact-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 216px;
      height: 104px;
      border-radius: 12px;
      border: 1px solid #fff;
      background: #fff;
      box-shadow: 0px 16px 40px 2px rgba(204, 204, 204, 0.2);
    }
  }
  ${Media.phone`
    padding: 0.76rem 0.32rem;
    &::after {
      top: 0;
      width: 5.4rem;
      height: 8.24rem;
      background: url(${CenterBgMobile}) center / cover no-repeat;
    }
    h3 {
      font-size: 0.48rem;
      font-weight: 700;
    }
    .map-wrapper {
      justify-content: start;
      flex-direction: column;
      row-gap: 0.48rem;
      margin: 0.56rem 0 0.76rem;
      padding: 0.64rem 0.24rem 0.24rem; 
      width: 100%;
      height: auto;
      border-radius: 0.32rem;
      &::after {
        top: 0;
        left: 100%;
        transform: translateX(-100%);
        width: 1.28rem;
        height: 1.28rem;
        background: url(${LocationMobile}) center / cover no-repeat;
      }
      .map-left {
        padding-left: 0.26rem;
        width: 100%;
        h4 {
          font-size: 0.4rem;
        }
        p {
          width: 100%;
          margin-top: 0.16rem;
          font-size: 0.32rem;
        }
      }
      .map-right {
        width: 100%;
        height: 3.58rem;
        border-radius: 0.2rem;
      }
    }
    .contact-container {
      justify-content: center;
      flex-wrap: wrap;
      gap: 0.26rem;
      margin-top: 0.56rem;
      .contact-wrapper {
        width: 2.11rem;
        height: 1.44rem;
        border-radius: 0.12rem;
        box-shadow: 0px 0px 26px 2px rgba(204, 204, 204, 0.20);
        img {
          width: 0.7rem;
        }
      }
    }
  `}
`
