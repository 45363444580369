import styled from 'styled-components'
import modalBg from '../../../images/modal-bg.png'
import modalBgMobil from '../../../images/modal-bg-mobil.png'
import { Media } from '@raylink-overseas/common/theme'

export const ModalBox = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-main {
    width: 500px;
    padding: 173px 64px 52px;
    border-radius: 16px;
    background: url(${modalBg}) no-repeat;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .modal-img {
    position: absolute;
    top: -56px;
    &.web {
      display: inline-block;
    }
    &.mobil {
      display: none;
    }
  }
  .modal-btn {
    padding: 12px 48px;
    margin-top: 32px;
  }
  ${Media.phone`
    .modal-main {
      width: 6.46rem;
      padding: 3.24rem 0.74rem 1.04rem;
      background: url(${modalBgMobil}) no-repeat;
      background-size: contain;
      background-color: #fff;
    }
    .modal-img {
      position: absolute;
      top: -0.84rem;
      &.mobil {
        width: 4.33rem;
        display: inline-block;
      }
      &.web {
        display: none;
      }
    }
    .modal-btn {
      padding: 0.24rem 1.28rem;
      margin-top: 0.64rem;
    }
  `}
`
