import { Media, color } from '@raylink-overseas/common/theme'
import styled from 'styled-components'
import TopBg from '../../../../images/contact/top-bg.png'
import TopBgMobile from '../../../../images/contact/top-bg-mobile.png'

export const ContactTopWrapper = styled.div`
  position: relative;
  padding: 148px 0 112px;
  background: url(${TopBg}) center / cover no-repeat;
  h1 {
    color: #1f2329;
    font-size: 48px;
    font-weight: 800;
    text-align: center;
  }
  .desc {
    margin-top: 10px;
    color: ${color.textDark};
    text-align: center;
    font-size: 18px;
    line-height: 1.5;
  }
  .card-container {
    display: flex;
    justify-content: space-between;
    margin-top: 56px;
    z-index: 99;
  }
  ${Media.phone`
    padding: 1.8rem 0.52rem 0.8rem;
    background: url(${TopBgMobile}) center / cover no-repeat;
    h1 {
      font-size: 0.8rem;
    }
    .desc {
      margin-top: 0.12rem;
      padding: 0 0.3rem;
      font-size: 0.32rem;
    }
    .card-container {
      flex-wrap: wrap;
      row-gap: 0.32rem;
      margin-top: 0.64rem;
    }
  `}
`

export const Card = styled.div`
  width: 378px;
  padding: 43px 32px;
  border-radius: 24px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
  h4 {
    margin-top: 12px;
    color: #1f2329;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
  }
  p {
    margin-top: 12px;
    color: ${color.textDark};
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    & + p {
      margin-top: 1.5em;
    }
  }
  .btn {
    margin-top: 32px;
    padding: 14px 24px;
    width: 161px;
  }
  ${Media.phone`
    width: 100%;
    padding: 0.64rem 0.40rem;
    border-radius: 0.4rem;
    text-align: left;
    .header {
      display: flex;
      align-items: center;
      column-gap: 0.32rem;
      img {
        width: 0.96rem;
        height: 0.96rem;
      }
      h4 {
        margin: 0;
        font-size: 0.4rem;
      }
    }
    p {
      margin-top: 0.28rem;
      font-size: 0.28rem;
    }
    .btn {
      margin-top: 0.4rem;
      padding: 0.24rem 0;
      width: 50%;
      border-radius: 0.12rem;
      color: #FFF;
      font-size: 0.28rem;
      font-weight: 500;
    }
  `}
`

export const ContactLink = styled.a`
  font-size: inherit;
  color: ${color.primary};
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
`
