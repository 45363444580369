import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'

export const MessageListBox = styled.div`
  width: max-content;
  max-width: 1200px;
  position: absolute;
  top: 92px;
  left: 0;
  right: 0;
  margin: 0 auto;
  ${Media.phone`
    top: 1.32rem;
    max-width: 6.86rem;
  `}
`

export const MessageItemBox = styled.div`
  padding: 11px 12px;
  margin-bottom: 8px;
  display: flex;
  align-items: start;
  gap: 6px;
  font-size: 14px;
  font-weight: 400;
  color: ${color.textDark};
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.4);

  .message-icon {
    width: 16px;
  }

  ${Media.phone`
    font-size: 0.28rem;
    line-height: 0.32rem;
    .message-icon {
      width: 0.32rem;
    }
  `}
`
