import React from 'react'
import { TextareaBox } from './index.style'

const Input: React.FC<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>
> = props => {
  return <TextareaBox {...props} />
}

export default Input
