import React, { useEffect, useRef } from 'react'
import { Warpper } from '@raylink-overseas/common/components'
import { useMedia } from '@raylink-overseas/common/hooks'
import { ContactWrapper } from './atoms'
import Facebook from '../../../../images/contact/facebook.svg'
import FacebookMobile from '../../../../images/contact/facebook-mobile.svg'
import Linkedin from '../../../../images/contact/linkedin.svg'
import LinkedinMobile from '../../../../images/contact/linkedin-mobile.svg'
import Youtube from '../../../../images/contact/youtube.svg'
import YoutubeMobile from '../../../../images/contact/youtube-mobile.svg'
import Discord from '../../../../images/contact/discord.png'
import DiscordMobile from '../../../../images/contact/discord-mobile.png'
import Instagram from '../../../../images/contact/instagram.png'
import InstagramMobile from '../../../../images/contact/instagram-mobile.png'

const contactInfoList = [
  {
    img: Facebook,
    mobileImg: FacebookMobile,
    link: 'https://www.facebook.com/AvicaSoftware',
  },
  {
    img: Linkedin,
    mobileImg: LinkedinMobile,
    link: 'https://www.linkedin.com/company/avica-software/',
  },
  {
    img: Youtube,
    mobileImg: YoutubeMobile,
    link: 'https://www.youtube.com/@AvicaRemoteDesktop',
  },
  {
    img: Instagram,
    mobileImg: InstagramMobile,
    link: 'https://www.instagram.com/avicasoftware/',
  },
  {
    img: Discord,
    mobileImg: DiscordMobile,
    link: 'https://discord.gg/QDEfMg93XS',
  },
]

/**
 * @param container 父元素
 * @see google map embed api 的使用文档 {@link https://developers.google.com/maps/documentation/embed/embedding-map?hl=zh-cn}
 * @see api key 申请地址 {@link https://console.cloud.google.com/apis/credentials?project=sonic-falcon-405706}
 */
const createEmbedMap = (
  container: Element,
  options: {
    apiKey: string // google cloud 申请的 key
    zoom?: number // 地图缩放比例
    address?: string // 地图中心地址
    placeId?: string // 地址 ID
    language?: string // 地图显示语言，默认为英文
  }
) => {
  if (typeof window === 'undefined') return
  const { apiKey, zoom = 17, address, placeId, language = 'en' } = options
  if (!address && !placeId) return
  const addressEncode = (() => {
    // 逗号后的查询字符串会被截断，不会传递给服务器，这里需一层转义
    if (address) return window.encodeURIComponent(address)
    if (placeId) return `place_id:${placeId}`
    return ''
  })()
  const src = `//www.google.com/maps/embed/v1/place?key=${apiKey}&q=${addressEncode}&zoom=${zoom}&language=${language}`
  const iframe = window.document.createElement('iframe')
  iframe.width = String(container.clientWidth + 400)
  iframe.height = String(container.clientHeight + 400)
  iframe.src = src
  iframe.loading = 'lazy'
  if (container.children) {
    container.innerHTML = ''
  }
  container.appendChild(iframe)
}

export default function ContactCenter() {
  const isMedia = useMedia()
  const mapRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!mapRef.current) return

    /**
     * 使用地址 embed api 有概率不显示红色标记位置，这里改用 placeId
     * @see 获取placeId {@link https://developers.google.com/maps/documentation/places/web-service/place-id?hl=zh-cn}
     */
    createEmbedMap(mapRef.current, {
      // placeId: 'ChIJz_MORBMZ2jERtLOlwmQtVS0', // International Plaza, Singapore 的 placeId (中文)
      // address: '10 Anson Road, International Plaza, Singapore',
      placeId: 'ChIJfdUohC8Z2jERCmOaEKmINug', // International Plaza, Singapore 的 placeId (英文)
      apiKey: 'AIzaSyA2kCi1s8O9Bd01qvzW0IwRrhd6KYxWWvI',
    })
  }, [])

  return (
    <ContactWrapper id="office-location">
      <Warpper>
        <h3>Avica Office Location</h3>
        <div className="map-wrapper">
          <div className="map-left">
            <h4 className="city">Singapore</h4>
            <p>
              <span>Address: </span>
              10 Anson Road, #27-18, International Plaza, Singapore
            </p>
          </div>
          <div ref={mapRef} id="map" className="map-right" />
        </div>
        <h3 id="keep-in-touch">Let&apos;s Keep in Touch</h3>
        <div className="contact-container">
          {contactInfoList.map((info, idx) => (
            <a
              key={idx}
              className="contact-wrapper"
              href={info.link}
              rel="nofollow noreferrer"
            >
              <img src={isMedia ? info.mobileImg : info.img} alt="" />
            </a>
          ))}
        </div>
      </Warpper>
    </ContactWrapper>
  )
}
