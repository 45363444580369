import React from 'react'
import Button from '@raylink-overseas/common/components/button'
import { ModalBox } from './index.style'
import modalImg from '../../../images/modal-img.png'
import modalImgMedia from '../../../images/modal-img-mobil.png'

interface Props {
  className?: string
  show: boolean
  okText?: string
  children: React.ReactNode
  onOk: () => void
}

const Modal = (props: Props) => {
  const { show, okText, onOk, ...rest } = props
  return (
    show && (
      <ModalBox {...rest}>
        <div className="modal-main">
          <img
            className="modal-img web"
            src={modalImg}
            alt="Avica Remote Desktop Software"
            loading="lazy"
          />
          <img
            className="modal-img mobil"
            src={modalImgMedia}
            alt="Avica Remote Desktop Software"
            loading="lazy"
          />
          <div>{props.children}</div>
          <Button className="modal-btn" onClick={onOk}>
            {okText || 'OK'}
          </Button>
        </div>
      </ModalBox>
    )
  )
}

export default Modal
