import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'

export const FormItemBox = styled.div`
  width: 100%;
  padding: 0 8px;
  margin-bottom: 14px;
  display: inline-flex;
  flex-direction: column;
  label {
    margin-bottom: 8px;
    color: #1f2329;
    font-size: 16px;
    font-weight: 500;
    .required {
      color: ${color.textDark};
      font-size: 14px;
      font-weight: 400;
      margin-left: 5px;
    }
  }
  .form-item-content {
    display: flex;
    align-items: center;
    &.error-status {
      input {
        border-color: #f54a45;
      }
    }
  }
  .err-message {
    color: #f54a45;
    font-size: 14px;
    font-weight: 400;
    height: 18px;
    line-height: 18px;
    margin-top: 2px;
  }
  ${Media.phone`
    padding: 0;
    margin-bottom: 0.16rem;
    label {
      margin-bottom: 0.16rem;
      font-size: 0.28rem;
      .required {
        margin-left: 0.1rem;
        font-size: 0.28rem;
      }
    }
    .err-message {
      font-size: 0.28rem;
      height: 0.36rem;
      line-height: 0.36rem;
      margin-top: 0.04rem;
    }
  `}
`
