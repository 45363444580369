import styled from 'styled-components'

import selectArrow from '../../../images/select-arrow.png'
import { Media, color } from '@raylink-overseas/common/theme'

export const SelectWrap = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  .arrow {
    width: 10px;
    height: 5px;
    background-image: url(${selectArrow});
    background-size: 10px 5px;
    background-repeat: no-repeat;
    position: absolute;
    right: 10px;
    bottom: 0;
    top: 0;
    margin: auto;
    &.up {
      transform: rotate(180deg);
    }
  }
  input {
    padding-right: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  ${Media.phone`
    .arrow {
      width: 0.2rem;
      height: 0.1rem;
      background-size: 0.2rem 0.1rem;
      right: 0.2rem;
    }
    input {
      padding-right: 0.52rem;
    }
  `}
`

export const Options = styled.div`
  width: 100%;
  max-height: 260px;
  padding: 4px;
  overflow-y: auto;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(204, 204, 204, 0.3);
  position: absolute;
  top: 42px;
  z-index: 1;
  display: none;
  transition: all 0.1s ease-out;
  &.show {
    display: flex;
    flex-direction: column;
  }
  .option-item {
    padding: 6px;
    border-radius: 4px;
    color: #1f2329;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    &:hover {
      background-color: #f6f6f6;
    }
    &.active {
      background-color: #f5f7ff;
      color: ${color.primary};
    }
  }
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: #dedede;
  }
  ${Media.phone`
    max-height: 5.2rem;
    padding: 0.08rem;
    border-radius: 0.12rem;
    top: 0.84rem;
    .option-item {
      padding: 0.12rem;
      border-radius: 0.08rem;
      font-size: 0.32rem;
    }
    &::-webkit-scrollbar {
      height: 0.1rem;
      width: 0.1rem;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.3rem;
    }
  `}
`
