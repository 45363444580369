import React from 'react'
import { Warpper, Button } from '@raylink-overseas/common/components'
import { Card, ContactLink, ContactTopWrapper } from './atoms'
import Message from '../../../../images/contact/message.svg'
import ChatUs from '../../../../images/contact/chat_us.svg'
import Email from '../../../../images/contact/email.svg'

export default function ContactTop() {
  /**
   * 获取聊天盒子的状态：关闭或打开
   */
  const getChatBoxStatus = () => {
    if (typeof window === 'undefined') return false
    const el = window.document.querySelector(
      '.widget-visible iframe:nth-child(2)'
    )
    return !!el && el.className === 'open'
  }

  /**
   * 聊天盒关闭时，模拟点击聊天盒按钮
   */
  const clickChatBtn = () => {
    if (getChatBoxStatus()) return
    if (typeof window === 'undefined') return
    const iframe = window.document.querySelector(
      '.widget-visible iframe'
    ) as HTMLIFrameElement | null
    if (!iframe) return
    const chatBtn = iframe.contentWindow?.document?.querySelector(
      '.tawk-min-container button'
    ) as HTMLElement
    if (chatBtn) chatBtn.click()
  }

  return (
    <ContactTopWrapper id="contact-us">
      <Warpper>
        <h1>Contact Us</h1>
        <p className="desc">
          Discover the optimal way to connect and get assistance from Avica.
        </p>
        <div className="card-container">
          <Card>
            <div className="header">
              <img src={Email} alt="" />
              <h4>Email Us</h4>
            </div>
            <p>
              For any questions about Avica products, please refer to our{' '}
              <ContactLink href="https://www.avica.link/help-center/">
                Help Center
              </ContactLink>{' '}
              or contact us via email{' '}
              <ContactLink href="mailto:support@avica.link">
                support@avica.link
              </ContactLink>
              .
            </p>
            <p>
              To learn Avica Business plan or book a demo, please email{' '}
              <ContactLink href="mailto:sales@avica.link">
                sales@avica.link
              </ContactLink>
              .
            </p>
          </Card>
          <Card>
            <div className="header">
              <img src={Message} alt="" />
              <h4>Message Us</h4>
            </div>
            <p>
              To customize an enterprise-level remote desktop solution, please
              send us a message with your requirements.
            </p>
            <Button className="btn" as="a" href="#customize-your-plan">
              Send Message
            </Button>
          </Card>
          <Card>
            <div className="header">
              <img src={ChatUs} alt="" />
              <h4>Chat Us</h4>
            </div>
            <p>
              Contact us through our online chat tool for any inquiries, and
              we&apos;ll respond to you promptly.
            </p>
            <Button className="btn" onClick={clickChatBtn}>
              Start Chat
            </Button>
          </Card>
        </div>
      </Warpper>
    </ContactTopWrapper>
  )
}
