import React from 'react'
import { Layout } from '@raylink-overseas/common/components'
import ContactTop from './components/contact-top'
import ContactCenter from './components/contact-center'
import ContactBottom from './components/contact-bottom'

export default function Contact() {
  return (
    <Layout pageType="contact">
      <ContactTop />
      <ContactCenter />
      <ContactBottom />
    </Layout>
  )
}
