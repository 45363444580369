import React from 'react'
import { CheckboxWrapper } from './index.style'

interface Props {
  placeholder?: string
  id: string
  label: string | React.ReactNode
  onChange: (checked: boolean) => void
}

const Checkbox = (props: Props) => {
  const { placeholder, id, onChange = () => {} } = props

  return (
    <CheckboxWrapper>
      <div className="checkbox">
        <input
          type="checkbox"
          {...props}
          onChange={() => {}}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.checked)
          }
          placeholder={placeholder}
        />
        <div className="box" />
      </div>
      <label htmlFor={id}>{props.label}</label>
    </CheckboxWrapper>
  )
}

export default Checkbox
