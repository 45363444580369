import React, { useMemo, useState } from 'react'
import { API, Axios } from '@raylink-overseas/common/api'
import { ContactBottomWrapper, Form } from './atoms'
import Button from '@raylink-overseas/common/components/button'
import { utmDecodeUri } from '@raylink-overseas/common/utils/utmDecodeUri'
import { Storage } from '@raylink-overseas/common/constants/storage'
import Message from '../../../components/Message'
import FormItem from '../../../components/FormItem'
import Input from '../../../components/Input'
import Select from '../../../components/Select'
import Textarea from '../../../components/Textarea'
import Checkbox from '../../../components/Checkbox'
import Modal from '../../../components/Modal'
import { countryList } from '../../config/country-by-calling-code'
import { getCookie } from '@raylink-overseas/common/utils'

interface FormData {
  [key: string]: string | number | undefined
  phone: string
}

export default function ContactBottom() {
  const http = new Axios(API.host)
  const [formData, setFormData] = useState<FormData>({ phone: '' })
  const [callingCode, setCallingCode] = useState<string | number>()
  const [showMessage, setShowMessage] = useState<{ [key: string]: boolean }>({})
  const [validateErr, setValidateErr] = useState<{ [key: string]: number }>({})
  const [isCheckTerms, setIsCheckTerms] = useState<boolean>(false)
  const [isCheckPolicy, setIsCheckPolicy] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [modalShow, setModalShow] = useState<boolean>(false)

  const howMany = [
    { value: '1-5' },
    { value: '6-25' },
    { value: '26-100' },
    { value: '101-500' },
    { value: '501+' },
  ]

  const validateRes = useMemo(() => {
    const requiredArr = [
      'name',
      'email',
      'company',
      'nation',
      'phone',
      'useRegulations',
    ]
    const requiredErrRes = requiredArr.find(key => !formData[key])
    const validateErrRes = Object.keys(validateErr).find(key => {
      return validateErr[key]
    })
    return requiredErrRes || validateErrRes
  }, [formData, validateErr])

  const changeTriggerVali = (valueKeyArr: { [key: string]: string }[]) => {
    valueKeyArr.forEach(({ key, value }) => {
      if (!value || key === 'email') {
        setShowMessage({ ...showMessage, [key]: true })
      }
      formData[key] = value
      setFormData({ ...formData })
    })
  }

  const inputPhone = (value: string) => {
    if (value && !/^[0-9][0-9 ]*$/.test(value)) return
    changeTriggerVali([{ key: 'phone', value }])
  }

  const validate = (res: { [key: string]: number }) => {
    validateErr[res.prop] = res.value
    setValidateErr({ ...validateErr })
  }

  const reset = () => {
    setFormData({ phone: '' })
    setLoading(false)
    setShowMessage({})
    setIsCheckTerms(false)
    setIsCheckPolicy(false)
    ;(document.querySelector('#contactForm') as HTMLFormElement).reset()
  }

  const submit = () => {
    if (loading) return
    if (!(isCheckTerms && isCheckPolicy)) {
      Message.warning(
        'Please agree to Avcia Terms of Service and Privacy Policy'
      )
      return
    }

    setLoading(true)
    formData.phone = `+${callingCode}${formData.phone}`

    const sourceUrl = getCookie(Storage.comefrom) || ''
    const params = {
      ...formData,
      sourceLink: utmDecodeUri(sourceUrl),
    }
    http.post(API.sendContact, params).then(res => {
      if (res?.result) {
        setModalShow(true)
      } else {
        Message.error(res?.message || '')
        reset()
      }
    })
  }

  const closeModal = () => {
    setModalShow(false)
    reset()
  }

  return (
    <ContactBottomWrapper id="customize-your-plan">
      <h2>Contact Us to Customize Your Plan</h2>
      <Form id="contactForm">
        <FormItem
          label="Name"
          rules={[{ required: true, message: 'Please enter your name' }]}
          formValue={formData.name}
          showMessage={showMessage.name}
        >
          <Input
            maxLength={30}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              changeTriggerVali([{ key: 'name', value: e.target.value }])
            }
          />
        </FormItem>
        <FormItem
          label="Email Address"
          formValue={formData.email}
          rules={[
            { required: true, message: 'Please enter your email address' },
            { type: 'email', message: 'The email address is invalid.' },
          ]}
          getValidateRes={{ prop: 'email', validate }}
          showMessage={showMessage.email}
        >
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              changeTriggerVali([{ key: 'email', value: e.target.value }])
            }
          />
        </FormItem>
        <FormItem
          className="set-width"
          label="Company"
          formValue={formData.company}
          rules={[
            { required: true, message: 'Please enter your company name' },
          ]}
          showMessage={showMessage.company}
        >
          <Input
            maxLength={30}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              changeTriggerVali([{ key: 'company', value: e.target.value }])
            }
          />
        </FormItem>
        <FormItem className="set-width" label="Job Title">
          <Input
            maxLength={30}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, position: e.target.value })
            }
          />
        </FormItem>
        <FormItem label="Industry">
          <Input
            maxLength={30}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, industry: e.target.value })
            }
          />
        </FormItem>
        <FormItem
          className="set-width"
          label="Country"
          formValue={formData.nation}
          rules={[{ required: true, message: 'Please select' }]}
          showMessage={showMessage.nation}
        >
          <Select
            placeholder="Please select"
            options={countryList}
            valueKey="country"
            labelKey="country"
            value={formData.nation}
            onChange={option => {
              changeTriggerVali([
                { key: 'nation', value: String(option.country) },
              ])
              setCallingCode(option.calling_code)
            }}
          />
        </FormItem>
        <FormItem
          className="set-width"
          label="Phone Number"
          formValue={formData.phone}
          rules={[
            { required: true, message: 'Please enter your phone number' },
          ]}
          showMessage={showMessage.phone}
        >
          <Input
            maxLength={30}
            value={formData.phone}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              inputPhone(e.target.value)
            }
          />
        </FormItem>
        <FormItem
          label="How many people would be using Avica?"
          formValue={formData.useRegulations}
          rules={[{ required: true, message: 'Please select' }]}
          showMessage={showMessage.useRegulations}
        >
          <Select
            placeholder="Plesse select"
            options={howMany}
            labelKey="value"
            value={formData.useRegulations}
            onChange={option =>
              changeTriggerVali([
                { key: 'useRegulations', value: String(option.value) },
              ])
            }
          />
        </FormItem>
        <FormItem label="What remote challenges are you looking to solve?">
          <Textarea
            maxLength={200}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setFormData({ ...formData, requirement: e.target.value })
            }
          />
        </FormItem>
        <div className="agree">
          <FormItem className="agree-item">
            <Checkbox
              id="terms"
              label={
                <div>
                  I have read and agree to the Avica{' '}
                  <a
                    href="https://www.avica.link/terms-and-conditions.html"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    Terms of Service
                  </a>{' '}
                  (required).
                </div>
              }
              onChange={value => setIsCheckTerms(value)}
            />
          </FormItem>
          <FormItem className="agree-item">
            <Checkbox
              id="policy"
              label={
                <div>
                  I acknowledge the Avica{' '}
                  <a
                    href="https://www.avica.link/privacy-policy.html"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    Privacy Policy
                  </a>{' '}
                  (required).
                </div>
              }
              onChange={value => setIsCheckPolicy(value)}
            />
          </FormItem>
          <FormItem className="agree-item">
            <Checkbox
              id="marketing"
              label="I agree to have Marketing Activities directed to me by and receive marketing and promotional information from Avica, including via email and social media (optional)."
              onChange={value =>
                setFormData({ ...formData, market: value ? 1 : 0 })
              }
            />
          </FormItem>
        </div>
        <FormItem className="send-btn">
          <Button
            type="button"
            id="contact-us"
            rel="nofollow"
            onClick={submit}
            disabled={Boolean(validateRes)}
          >
            Send
          </Button>
        </FormItem>
      </Form>
      <Modal className="success-modal" show={modalShow} onOk={closeModal}>
        <span className="success-text">Good Job! </span>
        <span className="success-text">Thanks for submitting the form!</span>
      </Modal>
    </ContactBottomWrapper>
  )
}
