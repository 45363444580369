import styled from 'styled-components'
import { Media } from '@raylink-overseas/common/theme'
import BottomBg from '../../../../images/contact/bottom-bg.png'
import BottomBgMobile from '../../../../images/contact/bottom-bg-mobile.png'

export const ContactBottomWrapper = styled.div`
  padding: 102px 0 112px;
  height: 1245px;
  background: url(${BottomBg}) center / cover no-repeat;
  h2 {
    margin-bottom: 64px;
    color: #1f2329;
    text-align: center;
    font-size: 40px;
    font-weight: 800;
    text-align: center;
  }
  #contactForm {
    margin-left: 50%;
  }
  ${Media.phone`
    position: relative;
    padding: 0 0.52rem;
    height: auto;
    background: #fff;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 0;
      height: 4.64rem;
      background: url(${BottomBgMobile}) top center / cover no-repeat;
    }
    h2 {
      position: relative;
      z-index: 99;
      font-size: 0.48rem;
      font-weight: 700;
    }
    #contactForm {
      position: relative;
      z-index: 99;
      margin: 3.84rem 0 0.96rem;
    }
  `}
`

export const Form = styled.form`
  font-family: Mark Pro;
  box-sizing: border-box;
  width: 592px;
  padding: 40px 40px 46px;
  flex-shrink: 0.3;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 32px 30px 0px rgba(0, 57, 125, 0.05);
  backdrop-filter: blur(15px);
  .set-width {
    width: 50%;
  }
  .agree {
    margin-top: 18px;
    .agree-item {
      margin-bottom: 13px;
    }
  }
  .send-btn {
    margin-top: 19px;
    .form-item-content {
      align-self: end;
    }
    button {
      padding: 12px 60px;
      font-size: 16px;
      font-weight: 500;
    }
  }
  ${Media.phone`
    width: 100%;
    padding: 0;
    box-shadow: none;
    .set-width {
      width: 100%;
    }
    .agree {
      margin-top: 0.12rem;
      .agree-item {
        margin-bottom: 0.24rem;
      }
    }
    .send-btn {
      margin-top: 0.2rem;
      margin-bottom: 0;
      padding: 0;
      .form-item-content {
        width: 100%
      }
      button {
        flex: 1;
        padding: 0;
        height: 0.92rem;
        font-size: 0.32rem;
      }
    }
  `}
`
