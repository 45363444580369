import { Media, color } from '@raylink-overseas/common/theme'
import styled from 'styled-components'

export const TextareaBox = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  padding: 11px 10px;
  border-radius: 6px;
  border: 1px solid #d9dbdd;
  outline: none;
  color: #1f2329;
  font-family: Mark Pro;
  font-size: 16px;
  font-weight: 400;
  resize: none;
  ::placeholder {
    font-family: Mark Pro;
    color: #848484;
    font-size: 16px;
    font-weight: 400;
  }
  &:focus,
  &:hover {
    border-color: ${color.primary};
  }
  ${Media.phone`
    padding: 0.22rem 0.2rem;
    border-radius: 0.12rem;
    font-size: 0.32rem;
    ::placeholder {
      font-size: 0.32rem;
    }
  `}
`
