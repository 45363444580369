import React, { useEffect, useState } from 'react'
import Input from '../Input/index'
import { SelectWrap, Options } from './index.style'

type keyValue = {
  [key: string]: string | number
}

interface Props {
  placeholder?: string
  valueKey?: string
  labelKey?: string
  options: keyValue[]
  value?: string | number
  onChange?: (item: keyValue) => void
}

let selectID = 0

const Select = (props: Props) => {
  const {
    placeholder,
    valueKey = 'value',
    labelKey = 'label',
    options,
    value = '',
    onChange = () => {},
    ...rest
  } = props
  const [selectValue, setSelectValue] = useState<string | number>('')
  const [optionShow, setOptionShow] = useState<boolean>(false)
  const [activeOption, setActiveOPtion] = useState<string | number>()
  const [currentId] = useState(() => {
    selectID += 1
    return selectID
  })

  const onSelect = (item: keyValue) => {
    onChange(item)
    setSelectValue(item[labelKey])
    setActiveOPtion(item[valueKey])
    setOptionShow(false)
  }

  useEffect(() => {
    setSelectValue(value)
  }, [value])

  useEffect(() => {
    const hideOptions = (e: MouseEvent) => {
      const currentSeletor = document.querySelector(`#select${currentId}`)
      if (e.target !== currentSeletor) {
        setOptionShow(false)
      }
    }
    document.addEventListener('click', hideOptions)
    return () => {
      document.removeEventListener('click', hideOptions)
    }
  }, [currentId])

  return (
    <SelectWrap {...rest}>
      <div style={{ display: 'none' }}>{currentId}</div>
      <Input
        id={`select${currentId}`}
        readOnly
        value={selectValue}
        placeholder={placeholder}
        onMouseDown={(e: React.MouseEvent<HTMLInputElement>) =>
          e.preventDefault()
        }
        onClick={() => setOptionShow(!optionShow)}
      />
      <Options className={optionShow ? 'show' : undefined}>
        {options.map((item, index) => (
          <div
            className={`option-item ${
              activeOption === item[valueKey] ? 'active' : ''
            }`}
            key={index}
            onClick={() => onSelect(item)}
          >
            {item[labelKey]}
          </div>
        ))}
      </Options>
      <div className={`arrow ${optionShow ? 'up' : ''}`} />
    </SelectWrap>
  )
}

export default Select
