export const countryList = [
  {
    key: 1,
    country: 'Afghanistan',
    calling_code: 93,
  },
  {
    key: 2,
    country: 'Albania',
    calling_code: 355,
  },
  {
    key: 3,
    country: 'Algeria',
    calling_code: 213,
  },
  {
    key: 4,
    country: 'American Samoa',
    calling_code: 1684,
  },
  {
    key: 5,
    country: 'Andorra',
    calling_code: 376,
  },
  {
    key: 6,
    country: 'Angola',
    calling_code: 244,
  },
  {
    key: 7,
    country: 'Anguilla',
    calling_code: 1264,
  },
  {
    key: 8,
    country: 'Antarctica',
    calling_code: 672,
  },
  {
    key: 9,
    country: 'Antigua and Barbuda',
    calling_code: 1268,
  },
  {
    key: 10,
    country: 'Argentina',
    calling_code: 54,
  },
  {
    key: 11,
    country: 'Armenia',
    calling_code: 374,
  },
  {
    key: 12,
    country: 'Aruba',
    calling_code: 297,
  },
  {
    key: 13,
    country: 'Australia',
    calling_code: 61,
  },
  {
    key: 14,
    country: 'Austria',
    calling_code: 43,
  },
  {
    key: 15,
    country: 'Azerbaijan',
    calling_code: 994,
  },
  {
    key: 16,
    country: 'Bahamas',
    calling_code: 1242,
  },
  {
    key: 17,
    country: 'Bahrain',
    calling_code: 973,
  },
  {
    key: 18,
    country: 'Bangladesh',
    calling_code: 880,
  },
  {
    key: 19,
    country: 'Barbados',
    calling_code: 1246,
  },
  {
    key: 20,
    country: 'Belarus',
    calling_code: 375,
  },
  {
    key: 21,
    country: 'Belgium',
    calling_code: 32,
  },
  {
    key: 22,
    country: 'Belize',
    calling_code: 501,
  },
  {
    key: 23,
    country: 'Benin',
    calling_code: 229,
  },
  {
    key: 24,
    country: 'Bermuda',
    calling_code: 1441,
  },
  {
    key: 25,
    country: 'Bhutan',
    calling_code: 975,
  },
  {
    key: 26,
    country: 'Bolivia',
    calling_code: 591,
  },
  {
    key: 27,
    country: 'Bosnia and Herzegovina',
    calling_code: 387,
  },
  {
    key: 28,
    country: 'Botswana',
    calling_code: 267,
  },
  {
    key: 29,
    country: 'Bouvet Island',
    calling_code: 55,
  },
  {
    key: 30,
    country: 'Brazil',
    calling_code: 55,
  },
  {
    key: 31,
    country: 'British Indian Ocean Territory',
    calling_code: 246,
  },
  {
    key: 32,
    country: 'Brunei',
    calling_code: 673,
  },
  {
    key: 33,
    country: 'Bulgaria',
    calling_code: 359,
  },
  {
    key: 34,
    country: 'Burkina Faso',
    calling_code: 226,
  },
  {
    key: 35,
    country: 'Burundi',
    calling_code: 257,
  },
  {
    key: 36,
    country: 'Cambodia',
    calling_code: 855,
  },
  {
    key: 37,
    country: 'Cameroon',
    calling_code: 237,
  },
  {
    key: 38,
    country: 'Canada',
    calling_code: 1,
  },
  {
    key: 39,
    country: 'Cape Verde',
    calling_code: 238,
  },
  {
    key: 40,
    country: 'Cayman Islands',
    calling_code: 1345,
  },
  {
    key: 41,
    country: 'Central African Republic',
    calling_code: 236,
  },
  {
    key: 42,
    country: 'Chad',
    calling_code: 235,
  },
  {
    key: 43,
    country: 'Chile',
    calling_code: 56,
  },
  {
    key: 44,
    country: 'China',
    calling_code: 86,
  },
  {
    key: 45,
    country: 'Christmas Island',
    calling_code: 61,
  },
  {
    key: 46,
    country: 'Cocos (Keeling) Islands',
    calling_code: 61,
  },
  {
    key: 47,
    country: 'Colombia',
    calling_code: 57,
  },
  {
    key: 48,
    country: 'Comoros',
    calling_code: 269,
  },
  {
    key: 49,
    country: 'Congo',
    calling_code: 242,
  },
  {
    key: 50,
    country: 'Cook Islands',
    calling_code: 682,
  },
  {
    key: 51,
    country: 'Costa Rica',
    calling_code: 506,
  },
  {
    key: 52,
    country: 'Croatia',
    calling_code: 385,
  },
  {
    key: 53,
    country: 'Cuba',
    calling_code: 53,
  },
  {
    key: 54,
    country: 'Cyprus',
    calling_code: 357,
  },
  {
    key: 55,
    country: 'Czech Republic',
    calling_code: 420,
  },
  {
    key: 56,
    country: 'Denmark',
    calling_code: 45,
  },
  {
    key: 57,
    country: 'Djibouti',
    calling_code: 253,
  },
  {
    key: 58,
    country: 'Dominica',
    calling_code: 1767,
  },
  {
    key: 59,
    country: 'Dominican Republic',
    calling_code: 1849,
  },
  {
    key: 60,
    country: 'East Timor',
    calling_code: 670,
  },
  {
    key: 61,
    country: 'Ecuador',
    calling_code: 593,
  },
  {
    key: 62,
    country: 'Egypt',
    calling_code: 20,
  },
  {
    key: 63,
    country: 'El Salvador',
    calling_code: 503,
  },
  {
    key: 64,
    country: 'England',
    calling_code: 44,
  },
  {
    key: 65,
    country: 'Equatorial Guinea',
    calling_code: 240,
  },
  {
    key: 66,
    country: 'Eritrea',
    calling_code: 291,
  },
  {
    key: 67,
    country: 'Estonia',
    calling_code: 372,
  },
  {
    key: 68,
    country: 'Ethiopia',
    calling_code: 251,
  },
  {
    key: 69,
    country: 'Falkland Islands',
    calling_code: 500,
  },
  {
    key: 70,
    country: 'Faroe Islands',
    calling_code: 298,
  },
  {
    key: 71,
    country: 'Fiji Islands',
    calling_code: 679,
  },
  {
    key: 72,
    country: 'Finland',
    calling_code: 358,
  },
  {
    key: 73,
    country: 'France',
    calling_code: 33,
  },
  {
    key: 74,
    country: 'French Guiana',
    calling_code: 594,
  },
  {
    key: 75,
    country: 'French Polynesia',
    calling_code: 689,
  },
  {
    key: 76,
    country: 'French Southern territories',
    calling_code: 262,
  },
  {
    key: 77,
    country: 'Gabon',
    calling_code: 241,
  },
  {
    key: 78,
    country: 'Gambia',
    calling_code: 220,
  },
  {
    key: 79,
    country: 'Georgia',
    calling_code: 995,
  },
  {
    key: 80,
    country: 'Germany',
    calling_code: 49,
  },
  {
    key: 81,
    country: 'Ghana',
    calling_code: 233,
  },
  {
    key: 82,
    country: 'Gibraltar',
    calling_code: 350,
  },
  {
    key: 83,
    country: 'Greece',
    calling_code: 30,
  },
  {
    key: 84,
    country: 'Greenland',
    calling_code: 299,
  },
  {
    key: 85,
    country: 'Grenada',
    calling_code: 1473,
  },
  {
    key: 86,
    country: 'Guadeloupe',
    calling_code: 590,
  },
  {
    key: 87,
    country: 'Guam',
    calling_code: 1671,
  },
  {
    key: 88,
    country: 'Guatemala',
    calling_code: 502,
  },
  {
    key: 89,
    country: 'Guinea',
    calling_code: 224,
  },
  {
    key: 90,
    country: 'Guinea-Bissau',
    calling_code: 245,
  },
  {
    key: 91,
    country: 'Guyana',
    calling_code: 592,
  },
  {
    key: 92,
    country: 'Haiti',
    calling_code: 509,
  },
  {
    key: 93,
    country: 'Heard Island and McDonald Islands',
    calling_code: 672,
  },
  {
    key: 94,
    country: 'Holy See (Vatican City State)',
    calling_code: 379,
  },
  {
    key: 95,
    country: 'Honduras',
    calling_code: 504,
  },
  {
    key: 96,
    country: 'Hong Kong',
    calling_code: 852,
  },
  {
    key: 97,
    country: 'Hungary',
    calling_code: 36,
  },
  {
    key: 98,
    country: 'Iceland',
    calling_code: 354,
  },
  {
    key: 99,
    country: 'India',
    calling_code: 91,
  },
  {
    key: 100,
    country: 'Indonesia',
    calling_code: 62,
  },
  {
    key: 101,
    country: 'Iran',
    calling_code: 98,
  },
  {
    key: 102,
    country: 'Iraq',
    calling_code: 964,
  },
  {
    key: 103,
    country: 'Ireland',
    calling_code: 353,
  },
  {
    key: 104,
    country: 'Israel',
    calling_code: 972,
  },
  {
    key: 105,
    country: 'Italy',
    calling_code: 39,
  },
  {
    key: 106,
    country: 'Ivory Coast',
    calling_code: 225,
  },
  {
    key: 107,
    country: 'Jamaica',
    calling_code: 1876,
  },
  {
    key: 108,
    country: 'Japan',
    calling_code: 81,
  },
  {
    key: 109,
    country: 'Jordan',
    calling_code: 962,
  },
  {
    key: 110,
    country: 'Kazakhstan',
    calling_code: 7,
  },
  {
    key: 111,
    country: 'Kenya',
    calling_code: 254,
  },
  {
    key: 112,
    country: 'Kiribati',
    calling_code: 686,
  },
  {
    key: 113,
    country: 'Kuwait',
    calling_code: 965,
  },
  {
    key: 114,
    country: 'Kyrgyzstan',
    calling_code: 996,
  },
  {
    key: 115,
    country: 'Laos',
    calling_code: 856,
  },
  {
    key: 116,
    country: 'Latvia',
    calling_code: 371,
  },
  {
    key: 117,
    country: 'Lebanon',
    calling_code: 961,
  },
  {
    key: 118,
    country: 'Lesotho',
    calling_code: 266,
  },
  {
    key: 119,
    country: 'Liberia',
    calling_code: 231,
  },
  {
    key: 120,
    country: 'Libyan Arab Jamahiriya',
    calling_code: 218,
  },
  {
    key: 121,
    country: 'Liechtenstein',
    calling_code: 423,
  },
  {
    key: 122,
    country: 'Lithuania',
    calling_code: 370,
  },
  {
    key: 123,
    country: 'Luxembourg',
    calling_code: 352,
  },
  {
    key: 124,
    country: 'Macao',
    calling_code: 853,
  },
  {
    key: 125,
    country: 'North Macedonia',
    calling_code: 389,
  },
  {
    key: 126,
    country: 'Madagascar',
    calling_code: 261,
  },
  {
    key: 127,
    country: 'Malawi',
    calling_code: 265,
  },
  {
    key: 128,
    country: 'Malaysia',
    calling_code: 60,
  },
  {
    key: 129,
    country: 'Maldives',
    calling_code: 960,
  },
  {
    key: 130,
    country: 'Mali',
    calling_code: 223,
  },
  {
    key: 131,
    country: 'Malta',
    calling_code: 356,
  },
  {
    key: 132,
    country: 'Marshall Islands',
    calling_code: 692,
  },
  {
    key: 133,
    country: 'Martinique',
    calling_code: 596,
  },
  {
    key: 134,
    country: 'Mauritania',
    calling_code: 222,
  },
  {
    key: 135,
    country: 'Mauritius',
    calling_code: 230,
  },
  {
    key: 136,
    country: 'Mayotte',
    calling_code: 262,
  },
  {
    key: 137,
    country: 'Mexico',
    calling_code: 52,
  },
  {
    key: 138,
    country: 'Micronesia, Federated States of',
    calling_code: 691,
  },
  {
    key: 139,
    country: 'Moldova',
    calling_code: 373,
  },
  {
    key: 140,
    country: 'Monaco',
    calling_code: 377,
  },
  {
    key: 141,
    country: 'Mongolia',
    calling_code: 976,
  },
  {
    key: 142,
    country: 'Montserrat',
    calling_code: 1664,
  },
  {
    key: 143,
    country: 'Morocco',
    calling_code: 212,
  },
  {
    key: 144,
    country: 'Mozambique',
    calling_code: 258,
  },
  {
    key: 145,
    country: 'Myanmar',
    calling_code: 95,
  },
  {
    key: 146,
    country: 'Namibia',
    calling_code: 264,
  },
  {
    key: 147,
    country: 'Nauru',
    calling_code: 674,
  },
  {
    key: 148,
    country: 'Nepal',
    calling_code: 977,
  },
  {
    key: 149,
    country: 'Netherlands',
    calling_code: 31,
  },
  {
    key: 150,
    country: 'Netherlands Antilles',
    calling_code: 599,
  },
  {
    key: 151,
    country: 'New Caledonia',
    calling_code: 687,
  },
  {
    key: 152,
    country: 'New Zealand',
    calling_code: 64,
  },
  {
    key: 153,
    country: 'Nicaragua',
    calling_code: 505,
  },
  {
    key: 154,
    country: 'Niger',
    calling_code: 227,
  },
  {
    key: 155,
    country: 'Nigeria',
    calling_code: 234,
  },
  {
    key: 156,
    country: 'Niue',
    calling_code: 683,
  },
  {
    key: 157,
    country: 'Norfolk Island',
    calling_code: 672,
  },
  {
    key: 158,
    country: 'North Korea',
    calling_code: 850,
  },
  {
    key: 159,
    country: 'Northern Ireland',
    calling_code: 44,
  },
  {
    key: 160,
    country: 'Northern Mariana Islands',
    calling_code: 1670,
  },
  {
    key: 161,
    country: 'Norway',
    calling_code: 47,
  },
  {
    key: 162,
    country: 'Oman',
    calling_code: 968,
  },
  {
    key: 163,
    country: 'Pakistan',
    calling_code: 92,
  },
  {
    key: 164,
    country: 'Palau',
    calling_code: 680,
  },
  {
    key: 165,
    country: 'Palestine',
    calling_code: 970,
  },
  {
    key: 166,
    country: 'Panama',
    calling_code: 507,
  },
  {
    key: 167,
    country: 'Papua New Guinea',
    calling_code: 675,
  },
  {
    key: 168,
    country: 'Paraguay',
    calling_code: 595,
  },
  {
    key: 169,
    country: 'Peru',
    calling_code: 51,
  },
  {
    key: 170,
    country: 'Philippines',
    calling_code: 63,
  },
  {
    key: 171,
    country: 'Pitcairn',
    calling_code: 64,
  },
  {
    key: 172,
    country: 'Poland',
    calling_code: 48,
  },
  {
    key: 173,
    country: 'Portugal',
    calling_code: 351,
  },
  {
    key: 174,
    country: 'Puerto Rico',
    calling_code: 1939,
  },
  {
    key: 175,
    country: 'Qatar',
    calling_code: 974,
  },
  {
    key: 176,
    country: 'Reunion',
    calling_code: 262,
  },
  {
    key: 177,
    country: 'Romania',
    calling_code: 40,
  },
  {
    key: 178,
    country: 'Russian Federation',
    calling_code: 7,
  },
  {
    key: 179,
    country: 'Rwanda',
    calling_code: 250,
  },
  {
    key: 180,
    country: 'Saint Helena',
    calling_code: 290,
  },
  {
    key: 181,
    country: 'Saint Kitts and Nevis',
    calling_code: 1869,
  },
  {
    key: 182,
    country: 'Saint Lucia',
    calling_code: 1758,
  },
  {
    key: 183,
    country: 'Saint Pierre and Miquelon',
    calling_code: 508,
  },
  {
    key: 184,
    country: 'Saint Vincent and the Grenadines',
    calling_code: 1784,
  },
  {
    key: 185,
    country: 'Samoa',
    calling_code: 685,
  },
  {
    key: 186,
    country: 'San Marino',
    calling_code: 378,
  },
  {
    key: 187,
    country: 'Sao Tome and Principe',
    calling_code: 239,
  },
  {
    key: 188,
    country: 'Saudi Arabia',
    calling_code: 966,
  },
  {
    key: 189,
    country: 'Scotland',
    calling_code: 44,
  },
  {
    key: 190,
    country: 'Senegal',
    calling_code: 221,
  },
  {
    key: 191,
    country: 'Serbia',
    calling_code: 381,
  },
  {
    key: 192,
    country: 'Seychelles',
    calling_code: 248,
  },
  {
    key: 193,
    country: 'Sierra Leone',
    calling_code: 232,
  },
  {
    key: 194,
    country: 'Singapore',
    calling_code: 65,
  },
  {
    key: 195,
    country: 'Slovakia',
    calling_code: 421,
  },
  {
    key: 196,
    country: 'Slovenia',
    calling_code: 386,
  },
  {
    key: 197,
    country: 'Solomon Islands',
    calling_code: 677,
  },
  {
    key: 198,
    country: 'Somalia',
    calling_code: 252,
  },
  {
    key: 199,
    country: 'South Africa',
    calling_code: 27,
  },
  {
    key: 200,
    country: 'South Georgia and the South Sandwich Islands',
    calling_code: 500,
  },
  {
    key: 201,
    country: 'South Korea',
    calling_code: 82,
  },
  {
    key: 202,
    country: 'South Sudan',
    calling_code: 211,
  },
  {
    key: 203,
    country: 'Spain',
    calling_code: 34,
  },
  {
    key: 204,
    country: 'Sri Lanka',
    calling_code: 94,
  },
  {
    key: 205,
    country: 'Sudan',
    calling_code: 249,
  },
  {
    key: 206,
    country: 'Suriname',
    calling_code: 597,
  },
  {
    key: 207,
    country: 'Svalbard and Jan Mayen',
    calling_code: 47,
  },
  {
    key: 208,
    country: 'Swaziland',
    calling_code: 268,
  },
  {
    key: 209,
    country: 'Sweden',
    calling_code: 46,
  },
  {
    key: 210,
    country: 'Switzerland',
    calling_code: 41,
  },
  {
    key: 211,
    country: 'Syria',
    calling_code: 963,
  },
  {
    key: 212,
    country: 'Tajikistan',
    calling_code: 992,
  },
  {
    key: 213,
    country: 'Tanzania',
    calling_code: 255,
  },
  {
    key: 214,
    country: 'Thailand',
    calling_code: 66,
  },
  {
    key: 215,
    country: 'The Democratic Republic of Congo',
    calling_code: 243,
  },
  {
    key: 216,
    country: 'Togo',
    calling_code: 228,
  },
  {
    key: 217,
    country: 'Tokelau',
    calling_code: 690,
  },
  {
    key: 218,
    country: 'Tonga',
    calling_code: 676,
  },
  {
    key: 219,
    country: 'Trinidad and Tobago',
    calling_code: 1868,
  },
  {
    key: 220,
    country: 'Tunisia',
    calling_code: 216,
  },
  {
    key: 221,
    country: 'Turkey',
    calling_code: 90,
  },
  {
    key: 222,
    country: 'Turkmenistan',
    calling_code: 993,
  },
  {
    key: 223,
    country: 'Turks and Caicos Islands',
    calling_code: 1649,
  },
  {
    key: 224,
    country: 'Tuvalu',
    calling_code: 688,
  },
  {
    key: 225,
    country: 'Uganda',
    calling_code: 256,
  },
  {
    key: 226,
    country: 'Ukraine',
    calling_code: 380,
  },
  {
    key: 227,
    country: 'United Arab Emirates',
    calling_code: 971,
  },
  {
    key: 228,
    country: 'United Kingdom',
    calling_code: 44,
  },
  {
    key: 229,
    country: 'United States',
    calling_code: 1,
  },
  {
    key: 230,
    country: 'United States Minor Outlying Islands',
    calling_code: 1,
  },
  {
    key: 231,
    country: 'Uruguay',
    calling_code: 598,
  },
  {
    key: 232,
    country: 'Uzbekistan',
    calling_code: 998,
  },
  {
    key: 233,
    country: 'Vanuatu',
    calling_code: 678,
  },
  {
    key: 234,
    country: 'Venezuela',
    calling_code: 58,
  },
  {
    key: 235,
    country: 'Vietnam',
    calling_code: 84,
  },
  {
    key: 236,
    country: 'Virgin Islands, British',
    calling_code: 1,
  },
  {
    key: 237,
    country: 'Virgin Islands, U.S.',
    calling_code: 1,
  },
  {
    key: 238,
    country: 'Wales',
    calling_code: 44,
  },
  {
    key: 239,
    country: 'Wallis and Futuna',
    calling_code: 681,
  },
  {
    key: 240,
    country: 'Western Sahara',
    calling_code: 212,
  },
  {
    key: 241,
    country: 'Yemen',
    calling_code: 967,
  },
  {
    key: 242,
    country: 'Zambia',
    calling_code: 260,
  },
  {
    key: 243,
    country: 'Zimbabwe',
    calling_code: 263,
  },
]
