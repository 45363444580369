import styled from 'styled-components'
import iconSelected from '../../../images/icon-selected.png'
import { Media, color } from '@raylink-overseas/common/theme'

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  .checkbox {
    width: 14px;
    height: 19px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    .box {
      width: 100%;
      height: 14px;
      border-radius: 2px;
      border: 1px solid #d9dbdd;
      &::before {
        content: '';
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        height: 14px;
        background: url(${iconSelected}) 50% 50% no-repeat;
        border-radius: 2px;
        background-color: ${color.primary};
        font-size: 12px;
        color: #fff;
        opacity: 0;
      }
    }
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    &[type='checkbox']:checked + .box {
      border-color: transparent;
      &::before {
        opacity: 1;
      }
    }
  }
  label {
    margin: 0;
    margin-left: 10px;
    color: ${color.textDark};
    font-size: 14px;
    font-weight: 400;
    font-family: 'MarkPro';
  }
  ${Media.phone`
    .checkbox {
      width: 0.28rem;
      height: 0.38rem;
      .box {
        height: 0.28rem;
        border-radius: 0.04rem;
        &::before {
          width: 0.28rem;
          height: 0.28rem;
          border-radius: 0.04rem;
          font-size: 0.24rem;
        }
      }
    }
    label {
      margin-left: 0.2rem;
      font-size: 0.28rem;
    }
  `}
`
